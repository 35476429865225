export default {
  init() {
    let home_url = window.sage.home_url;
    $('.card-group .col:first-child > .card .card-body').addClass('card-active');
    if(window.sage.page_template != 'post'){
      $('#top-menu').sticky({ topSpacing: 0, zIndex: 88888 });
      let dt = $('#top-menu').sticky('update');
      //$('#top-menu').on('sticky-start', function() { $('.brand img').animate({top: '-200px'}, 300); });
      //$('#top-menu').on('sticky-end', function() { $('.brand img').animate({top: '0px'}, 300); });
      $(window).on('resize', function(){
        $('#top-menu').sticky('update');
      });
    }
    

    // JavaScript to be fired on all pages
    $('.bar-menu').click(function(e) {
      e.preventDefault();
      $('#main-menu').toggleClass('menu-active');
    });
    $( document ).ajaxComplete(function() {
      $('.aws_result_link_top').attr('href', '');
      $('.aws_result_link_top').click(function(e) {
        e.preventDefault();
      });
    });
    $('#manual-open, .manual-open').click(function(e) {
      e.preventDefault();
      $('#manual-menu').toggleClass('menu-active');
    });
    $('#servicing-ie-open, .servicing-ie-open').click(function(e) {
      e.preventDefault();
      $('#servicing-ie-menu').toggleClass('menu-active');
    });
    $('#servicing-uk-open, .servicing-uk-open').click(function(e) {
      e.preventDefault();
      $('#servicing-uk-menu').toggleClass('menu-active');
    });
    $('#buy-open, .buy-open').click(function(e) {
      e.preventDefault();
      $('#buy-menu').toggleClass('menu-active');
    });
    $('#media-open, .media-open').click(function(e) {
      e.preventDefault();
      $('#media-menu').toggleClass('menu-active');
    });
    $('.menu-close').click(function(e) {
      e.preventDefault();
      $('#back-prev').trigger('click');
      $(this).parent().parent().parent().parent().removeClass('menu-active');
    });
    $('#close-address').click(function(e){
      e.preventDefault();
      $(this).find('i').toggleClass('fa-xmark fa-arrow-down');
      $('#map-details').slideToggle();
    });
    $('a.card-body').click(function() {
      $('a.card-body').removeClass('card-active');
      $(this).toggleClass('card-active');
    });
    $('.external-dealer').click(function(e) {
      e.preventDefault();
      let title = $(this).data('title');
      let link = $(this).data('link');
      let original_content = $('#premium-content').html();
      let updated_content = '<h3>Just before you leave</h3><p>Not all '+ title +' stores are QTech stockists.<br/>For details of your nearest stockist go back to the QTech Premium dealer listing.<br/><br/>Alternatively, click Continue to go to the '+ title +' store locator.</p><a class="btn btn-secondary-light mt-3 mt-md-5 w-40 me-3" id="back-prev" href="#"><i class="fas fa-solid fa-angle-left"></i> Back</a> <a class="btn btn-white mt-3 mt-md-5 w-40 me-3" href="'+ home_url +'/premium-dealers/">QTech Premium dealer listing</a> <a class="btn btn-white-solid mt-3 mt-md-5 w-40" target="_blank" href="' + link + '">Continue</a>';
      $('#decorating-centre-list').hide();
      $('#addition-text').hide();
      $('#premium-content').html(updated_content);
      $('#back-prev').click(function(e) {
        e.preventDefault();
        $('#decorating-centre-list').show();
        $('#addition-text').show();
        $('#premium-content').html(original_content);
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    if(window.sage.page_template != 'post'){
      $('#top-menu').height($('.logo').height());
      $('#top-menu').sticky('update');
    }
  },
};
