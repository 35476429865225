function findPrice() {
  $('.ajax-loader-list').show();
  var ajaxurl = window.sage.ajax_url;
  $.ajax({
    url: ajaxurl,
    dataType: 'html',
    type: 'post',
    data:{
      action:'get_pricing_data',
    },
    success: function(data) {
      $('.ajax-loader-list').hide();
      $('.selected-price').fadeIn();
      $('.selected-price').html(data);
      $('html, body').animate({scrollTop:$('.selected-price').position().top -200}, 'slow');
    },
    error: function(errorThrown){
      $('.selected-price').html(errorThrown);
    },
  });
}