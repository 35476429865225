import Cookies from 'js-cookie';
var google, autocomplete, geocoder, sage;
var is_searched = false;
window.initAutocomplete = function() {
  autocomplete = new window.google.maps.places.Autocomplete((document.getElementById('filter')),{types: ['geocode']});
  autocomplete.addListener('place_changed', findAddress);
}

function geocodeLatLng(geocoder, lat, lng) {
  geocoder = new window.google.maps.Geocoder();
  var latlng = { lat: lat, lng: lng };
  geocoder.geocode({ location: latlng }, function(results, status) {
    if (status === 'OK') {
      if (results[0]) {
          $('#get_gps').html('<i class=\'fa fa-location-arrow fa-lg\'></i> ' + results[0].formatted_address);
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }
  });
}

function findAddress(lat, lng) {
    $('.ajax-loader-list').show();
    if($('#get_gps').hasClass( 'active-locate' )){
      Cookies.set('lat', lat, { expires: 7 });
      Cookies.set('lng', lng, { expires: 7 });
      console.log('test1');
    }else{
      var place = autocomplete.getPlace();
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      $('#get_gps').html('<i class=\'fa fa-location-arrow fa-lg\'></i> ' + place.adr_address);
      console.log('test2');
      Cookies.set('autocompleteaddress', place.adr_address.replace(/(<([^>]+)>)/ig,''), { expires: 7 });
    }
    var ajaxurl = window.sage.ajax_url;
    $.ajax({
      url: ajaxurl,
      dataType: 'html',
      type: 'post',
      data:{
        action:'get_location_data',
        lat:lat,
        lng:lng,
      },
      success: function(data) {
        $('.ajax-loader-list').hide();
        $('.selected-location').fadeIn();
        $('.selected-location').html(data);
        $('html, body').animate({scrollTop:$('.selected-location').position().top -200}, 'slow');
        is_searched = true;
      },
      error: function(errorThrown){
        $('.selected-location').html(errorThrown);
      },
    });
  }

  function get_location() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
            if(document.cookie.indexOf('autocompleteaddress=') < 0){
              findAddress(pos.lat, pos.lng);
              geocodeLatLng(geocoder, pos.lat, pos.lng);
            }else{
              $('#get_gps').html('<i class=\'fa fa-location-arrow fa-lg\'></i> ' + Cookies.get('autocompleteaddress'));
              findAddress(Cookies.get('lat'), Cookies.get('lng'));
            }


        }, function() {
          $('.manual-block').toggle();
          alert('Can\'t get your location, please try again');

        });
      } else {
        $('.manual-block').toggle();
        alert('Your browser doesn\'t support geolocation');
      }
  }

  $( '#get_gps' ).click(function() {
    $(this).html('<img id=\'preloader-locate\' src=\'' + window.sage.template_directory + '/assets/images/ajax-loader.svg\' />  Getting your location...');
    $('#filter').val('');
    $('.ajax-loader-list').show();
    $('.selected-location').hide();
    $('.location-title').val('');
    $('.location-address').val('');
    $(this).addClass('active-locate');
    Cookies.remove('autocompleteaddress');
    get_location();
  });

  $('#filter').focus(function() {
    $(this).val('');
    $('#get_gps').html('<i class=\'fa fa-map-marker fa-lg\'></i> Click to get your location');
    is_searched = false;
    $('#get_gps').removeClass('active-locate');
    $('.selected-location').hide();
    $('.location-title').val('');
    $('.location-address').val('');
  });
  
